#section1container {
    width: 100vw;
    height: 860px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    color: white;
}

#S1bg {
    width: 650px;
    height: 650px;
    background-image: url('../../files/S1bg.png');
    background-size: cover;
    position: relative;
}

#S1left {
    width: 620px;
    min-width: 620px;
    margin-left: 80px;
    margin-right: 40px;
}

#S1logo {
    width: 300px;
    height: 162px;
    background-image: url('../../files/logo.png');
    background-size: cover;
}

#S1title {
    font-family: SilkaL;
    display: flex;
    letter-spacing: 2px;
    margin-top: 40px;
    font-size: 56px;
}

#S1subtitle {
    margin-top: 4px;
    font-family: MontR;
    display: flex;
    font-size: 30px;
}

#S1tm {
    font-size: 14px;
    margin-top: 11px;
}

#S1text {
    font-family: MontR;
    margin-top: 33px;
    font-size: 18px;
    line-height: 150%;
}

#S1line {
    width: 100%;
    margin-top: 30px;
    height: 2px;
    background-color: #A8A9AA;
}
#mintcontainer {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#mintbutton {
    padding: 25px 25px;
    font-family: MontR;
    font-size: 32px;
    border: 1px solid #E2AE30;
    transition: 1s ease-in-out;
    cursor: pointer;
    margin-right: 20px;
}

#mintbutton:hover {
    color: #E2AE30;
    box-shadow: 0px 0px 20px 5px #E2AE30;
}

#S1info {
    display: flex;
    flex-direction: column;
}

#S1genesis {
    font-family: MontR;
    font-size: 30px;
    color: #E2AE30;
    margin-top: 5px;
}

#S1details {
    display: flex;
    flex-direction: row;
    font-family: MontR;
    font-size: 30px;
    justify-content: space-between;
    margin-top: 15px;
}

#S1count {
    margin-left: 10px;
}
#S1vertical {
     height: 25px;
     width: 1px;
     margin-top: 7px;
     background-color: white;
}

#S1price {
    font-size: 20px;
    margin-top: 10px;
    margin-right: 10px;
}

#topsocials {
    position: absolute;
    top: 0px;
    right: 30px;
}

@media only screen and (max-width: 1300px) {

    
    #S1bg {
        width: 450px;
        height: 450px;
        margin-top: 50px;
    }
    
    #S1left {
        width: 460px;
        min-width: 460px;
        margin-left: 80px;
        margin-right: 40px;
    }
    
    #S1logo {
        width: 250px;
        height: 134px;
        background-image: url('../../files/logo.png');
        background-size: cover;
    }
    
    #S1title {
        font-size: 49px;
    }

    #S1subtitle {
        font-size: 26px;
    }
    
    #S1text {
        text-align: justify;
        font-size: 17px;
    }
    
    #S1line {
        width: 700px;
    }
    #mintcontainer {
        width: 700px;
    }
    
    
}

@media only screen and (max-width: 1100px) {
    #section1container {
        height: 750px;
    }

    #S1bg {
        display: none;
    }

    #S1left {
        width: 780px;
        margin-left: 0px;
        margin-right: 0px;
    }

    #S1line {
        width: 780px;
    }
    #mintcontainer {
        width: 780px;
    }
}

@media only screen and (max-width: 950px) {

    #S1left {
        width: 650px;
    }

    #S1line {
        width: 650px;
    }
    #mintcontainer {
        width: 650px;
    }
}

@media only screen and (max-width: 700px) {

    #section1container {
        width: 100vw;
        height: auto;
    }

    #S1left {
        width: 90vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 351px;
    }

    #S1title {
        font-size: 36px;
    }

    #S1subtitle {
        font-size: 24px;
    }
    #S1tm {
        font-size: 11px;
        margin-top: 6px;
    }

    #S1text {
        width: 100%;
    }

    #S1line {
        width: 100%;
    }

    #mintcontainer {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    #mintbutton {
        padding: 30px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
        margin-bottom: 20px;
    }

    #S1details {
        margin-bottom: 30px;
    }
}