#section2container {
    width: 100vw;
    height: 470px;
    background-color: #E2dcdc;
    color: black;
    font-family: MontM;
}

.pictures {
    background-size: cover;
    width: 400px !important;
    height: 400px;
    margin-right: 22px;
    margin-left: 22px;
    margin-top: 34px;
}

@media only screen and (max-width: 500px) {
    .pictures {
        width: 300px !important;
        height: 300px;
    }

    #section2container {
        height: 370px;
    }
}