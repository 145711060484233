

#navwelcome2 {
    width: 120px;
    min-height: 65px;
    background-color:  black;
}

#navwelcome1 {
    display: none;
}
