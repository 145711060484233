.gallery-code-container {
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    width: 325px;
    height: 470px;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    box-shadow: 0px 5px 10px 0px rgb(160, 160, 160);
}


.gallery-img {
    width: 325px;
}

.tile-name {
    font-family: MontM;
    font-size: 25px;
    margin-left: 12px;
    margin-top: 12px;
}

.tile-alignment {
    font-family: MontM;
    font-size: 20px;
    margin-left: 12px;
    margin-top: 5px;
}

.tile-edition {
    font-family: MontM;
    font-size: 20px;
    margin-left: 12px;
    margin-top: 5px;
}

.bottom-banner {
    width: 104px;
    border-radius: 5px;
    position: absolute;
    background-color: black;
    color: #E2AE30;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 14px;
    right: 14px;
    font-family: MontM;
    height: 30px;
    font-size: 14px;
}