#section3container {
    width: 100vw;
    height: 1520px;
    display: flex;
    justify-content: flex-end;
    font-family: Varela;
    font-size: 18px;
    margin-bottom: 130px;
    position: relative;
}
#S2topcode {
    height: 630px;
    position: absolute;
    transform: translate(-141%, -102.5%);
    top: 50%;
    left: 50%;
}

#S2topimg {
    height: 480px;
    position: absolute;
    transform: translate(-106%, -100%);
    top: 50%;
    left: 50%;
}

#S2bottomcode {
    height: 820px;
    position: absolute;
    transform: translate(34%, 1.8%);
    top: 50%;
    left: 50%;
}

#S2bottomimg {
    height: 500px;
    position: absolute;
    transform: translate(10%, 30%);
    top: 50%;
    left: 50%;
}

#S2TC1 {
    position: absolute;
    transform: translate(10%, -99%);
    top: 50%;
    left: 50%;
    color: white;
    width: 450px;
}

#S2TC1title {
    font-size: 49px;
    font-family: MontR;
}

#S2TC1title2 {
    margin-top: 5px;
    font-size: 49px;
    font-family: MontR;
    color: #E2AE30;
    margin-bottom: 45px;
}

#S2TC1text {
    font-family: MontR;
    letter-spacing: 1px;
    line-height: 140%;
    text-align: justify;
}

#S2TC2 {
    position: absolute;
    transform: translate(-105%, 65%);
    top: 50%;
    left: 50%;
    color: white;
    width: 490px;
}

#sabetsocials {
    width: 110px;
    display: flex;
    margin-top: 30px;
    align-items: center;
    justify-content: space-between;
}

#S2TC2title {
    font-size: 49px;
    font-family: MontR;
    color: #E2AE30;
}

#S2TC2title2 {
    margin-top: 15px;
    font-size: 20px;
    font-family: MontR;
    margin-bottom: 45px;
}

#S2TC2text {
    font-family: MontR;
    letter-spacing: 1px;
    line-height: 140%;
    text-align: justify;
}

@media only screen and (max-width: 1300px) {
    #section3container {
        font-size: 17px;
    }
}

@media only screen and (max-width: 1070px) {
    #section3container {
        width: 100vw;
        height: auto;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 40px;
    }
    #S3vert1 {
        width: 95vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }

    #S2topcode {
        height: 600px;
        margin-left: -200px;
        position: static;
        transform: none;
    }
    
    #S2topimg {
        height: 400px;
        position: static;
        transform: none;
        margin-top: 170px;
        margin-bottom: -550px;
        margin-right: -100px;
    }

    #S2TC1 {
        position: static;
        width: 90%;
        transform: none;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 50px;
    }
    
    #S2TC1title {
        font-size: 42px;
    }
    
    #S2TC1title2 {
        text-align: center;
        font-size: 39px;
    }
    
    #S2TC1text {
        font-family: MontR;
        letter-spacing: 1px;
        line-height: 140%;
        text-align: justify;
    }

    #S3vert2 {
        width: 95vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }
    
    
    #S2bottomcode {
        height: 620px;
        position: static;
        transform: none;
    }
    
    #S2bottomimg {
        height: 400px;
        position: static;
        transform: none;
        margin-top: 120px;
        margin-bottom: -520px;
    }
    
    #S2TC2 {
        position: static;
        width: 90%;
        transform: none;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 60px;
    }
    
    #S2TC2title {
        font-size: 49px;
        font-family: MontR;
        color: #E2AE30;
    }
    
    #S2TC2title2 {
        margin-top: 15px;
        font-size: 20px;
        font-family: MontR;
        margin-bottom: 45px;
    }
    
    #S2TC2text {
        font-family: MontR;
        letter-spacing: 1px;
        line-height: 140%;
        text-align: justify;
        max-width: 550px;
        text-align: center;
    }


    #S2TC1text {
        max-width: 550px;
        text-align: center;
    }

}


@media only screen and (max-width: 500px) {

    #S2topcode {
        height: 100vw;
        position: static;
        transform: none;
        align-self: flex-start;
        margin-left: -12vw;
    }
    
    #S2topimg {
        height: 80vw;
        position: static;
        transform: none;
        align-self: flex-end;
        margin-right: 2vw;
        margin-top: 150px;
        margin-bottom: -110vw;
    }

    #S2TC2 {

        margin-top: 100px;
    }
    
    #S2bottomcode {
        height: 130vw;
        position: static;
        transform: none;
    }
    
    #S2bottomimg {
        height: 80vw;
        position: static;
        transform: none;
        margin-top: 150px;
        margin-bottom: -110vw;
    }
    
}