#rarity-inner {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: MontR;
    font-weight: 1000;
    min-height: calc(100vh - 100px)
}

#rarity-title {
    font-family: MontM;
    color: #E2AE30;
    width: 80%;
    font-size: 40px;
    margin-top: 50px;
}

.rarity-text {
    color: white;
    width: 80%;
    font-size: 18px;
    margin-top: 35px;
}

#rarity-last {
    margin-bottom: 50px;
}

@media only screen and (max-width: 600px) {
    #rarity-title {
        width: 90%;
        text-align: center;
    }
    
    .rarity-text {
        width: 90%;
        text-align: center;
    }
}