#footer {
    margin-top: 30px;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
}

#footerlogo {
    width: 200px;
    height: 108px;
    background-image: url('../../files/logo.png');
    background-size: cover;
}

#Fmintcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    width: 620px;
}

#Fmintbutton {
    padding: 18px 30px;
    font-family: MontR;
    padding-bottom: 0px;
    height: 55px;
    font-size: 27px;
    border: 1px solid #E2AE30;
    transition: 1s ease-in-out;
    margin-top: 5px;
}

#Fmintbutton:hover {
    color: #E2AE30;
    box-shadow: 0px 0px 20px 5px #E2AE30;
}

#Finfo {
    display: flex;
    flex-direction: column;
}

#Fgenesis {
    font-family: MontR;
    font-size: 27px;
    color: #E2AE30;
}

#Fdetails {
    display: flex;
    flex-direction: row;
    font-family: MontR;
    font-size: 27px;
    justify-content: space-between;
    margin-top: 15px;
}

#Fcount {
    margin-left: 10px;
}
#Fvertical {
     height: 25px;
     width: 1px;
     margin-top: 7px;
     background-color: white;
}

#Fprice {
    font-size: 20px;
    margin-top: 4px;
    margin-right: 10px;
}

.socials {
    display: flex;
    flex-direction: column;
    color: white;
    height: 120px;
    justify-content: space-between;
    font-size: 20px;
    align-items: center;
}

@media only screen and (max-width: 900px) {
    
    #footerlogo {
        margin-right: 0px;
        margin-bottom: 20px;
    }
    
    #Fmintcontainer {
        margin-top: 30px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        width: 350px;
    }
    
    #Fmintbutton {
        display: none;
    }
    
    #Fdetails {
        margin-bottom: 20px;
    }
    
    #Fcount {
        margin-left: 10px;
    }
    
}

@media only screen and (max-width: 600px) {
    #footer {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    
    #footerlogo {
        margin-right: 0px;
        margin-bottom: 20px;
    }
    
    #Fmintcontainer {
        margin-top: 30px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        width: 350px;
    }
    
    #Fmintbutton {
        font-size: 28px;
        padding-bottom: 0px;
        margin-bottom: 20px;
        display: flex;
    }
    
    #Fdetails {
        margin-bottom: 20px;
    }
    
    #Fcount {
        margin-left: 10px;
    }

    
    .socials {
        flex-direction: row;
        width: 120px;
        height: 20px;
        margin-top: 10px;
    }
    
}