#code-details-container {
    background-color: #E2dcdc;
    width: 100vw;
    min-height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

#details-main-container {
    display: flex;
    margin-bottom: 50px;
    margin-top: 70px;
}

#details-back {
    position: absolute;
    left: 0px;
    top: -50px;
    color: black;
}

#details-tile {
    width: 430px;
    margin-right: 30px;
    background-color: white;
    font-family: MontM;
    font-size: 25px;
    position: relative;
    box-shadow: 0px 5px 14px 0px rgb(150, 150, 150);
}

#details-img {
    width: 430px;
}

#details-name {
    font-size: 40px;
    margin-left: 20px;
    margin-top: 10px;
}

#details-alignment {
    margin-left: 20px;
    margin-top: 10px;
}

#details-edition {
    margin-left: 20px;
    margin-top: 10px;
}

#download {
    font-family: Silka;
    color: black;
    position: absolute;
    bottom: 20px;
    right: 20px;
    font-size: 20px;
}

#opensea {
    background-image: url('../../files/open.png');
    background-size: cover;
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 35px;
    height: 35px;
}

#details-right-top {
    display: flex;
}

#chakra-levels {
    font-family: MontM;
    font-size: 30px;
    margin-bottom: 20px;
}

.details-chakra-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 315px;
    font-family: MontM;
    margin-right: 20px;
}

.chakra-name {
    margin-left: 14px;
}

.chakra-score {
    font-family: MontSB;
    margin-left: auto;
}

#details-right-vline {
    height: 530px;
    width: 1px;
    background-color: black;
    margin-right: 20px;
}

#details-column2 {
    width: 240px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#details-vibration {
    font-family: MontM;
    font-size: 30px;
    margin-bottom: 20px;
}

#details-frequency {
    font-family: MontM;
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 40px;
}

#details-right-hline {
    width: 596px;
    height: 1px;
    background-color: black;
}

#details-description {
    width: 550px;
    font-family: SilkaL;
    font-weight: 1000;
}

#energy-container {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

#energy-logo {
    background-image: url('../../files/energy.png');
    width: 60px;
    height: 60px;
    background-size: cover;
    margin-right: 20px;
}

#energy-title {
    font-family: MontM;
    font-size: 28px;
}

#energy-alignment {
    font-family: MontSB;
    font-size: 28px;
}

@media only screen and (min-width: 1210px) {
    #details-tile {
        width: 520px;
    }
    
    #details-img {
        width: 520px;
    }
}

@media only screen and (min-width: 1340px) {
    #details-tile {
        width: 580px;
    }
    
    #details-img {
        width: 580px;
    }
}

@media only screen and (min-width: 1500px) {
    #details-tile {
        width: 620px;
        height: 850px;
    }
    
    #details-img {
        width: 620px;
    }
}


@media only screen and (min-width: 1700px) {
    #details-tile {
        margin-right: 100px;
    }

    .details-chakra-container {
        margin-right: 90px;
    }

    #details-right-vline {
        margin-right: 70px;
    }

    #details-right-hline {
        width: 686px;
    }

}

@media only screen and (min-width: 2100px) {
    #details-tile {
        margin-right: 200px;
    }

}

@media only screen and (max-width: 1090px) {

    #details-right-top {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #details-right-vline {
        margin-right: 0px;
        width: 350px;
        height: 1px;
        margin-bottom: 25px;
    }

    #details-right-hline {
        width: 350px;
        margin-top: 25px;
    }

    #details-right {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #details-tile {
        height: 700px;
    }

        #details-main-container {
            position: relative;
        }

    #details-right-bottom {
        position: absolute;
        left: 0px;
        top: 740px;
    }

    #details-description {
        width: 429px;
        text-align: justify;
    }

    #details-column1 {
        align-items: center;
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 850px) {
    #details-right-bottom {
        position: static;
    }

    #details-tile {
        margin-right: 0px;
    }
    
    #details-main-container {
        flex-direction: column;
    }

    #details-column1 {
        margin-top: 40px;
    }

    #details-right-hline {
        margin-bottom: 20px;
    }
}


@media only screen and (max-width: 420px) {

    #details-img {
        width: 360px;
    }

    #details-tile {
        margin-right: 0px;
        width: 360px;
    }

    #details-description {
        width: 360px;
    }

    #energy-container {
        width: 360px;
    }
    
    #energy-title-container {
        width: 260px;
    }

    #opensea {
        bottom: 60px;
        right: 20px;
        left: auto;
    }
}