
#mbinner {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

  #mintcontent {
    width: 540px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#minttitle {
    margin-top: 60px;
    font-family: MontM;
    color: #E2AE30;
    font-size: 50px;
    width: 100%;
    display: flex;
    letter-spacing: 8px;
    margin-bottom: 10px;
    text-align: center;
}

#mintcols {
    font-size: 19px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


#mintcol1 {
    width: 100%;
}



//section 1 modal

#modalmessagetext {
    text-align: center;
    margin-top: 34px;
    font-family: MontM;
    line-height: 135%;
    font-size: 17px;
}

.countbutton {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    background-color: #4e4e4e;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color linear .5s;
    cursor: pointer;
}

.countbutton:hover {
    background-color: #E2AE30;
    box-shadow: 0px 0px 5px 1px black;
}

#mintinput {
    color: white;
    font-size: 52px;
    font-family: MontM;
    display: flex;
    position: absolute;
    left: 18%;
    bottom: 23%;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 100px;
    border: 2px solid #E2AE30;
}

.invalid-feedback {
    position: absolute;
    left: 10%;
    bottom: 16.4%;
    color: red;
    font-size: 14px;
}

#plus {
    position: absolute;
    right: 15%;
    bottom: 41%;
    color: black;
}

#minus {
    position: absolute;
    right: 15%;
    bottom: 23.7%;
    color: black;
}

#modalclose {
    position: absolute;
    right: -14%;
    top: -10%;
    background-color: black;
    border: 2px solid #E2AE30;
    padding: 4px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 60px;
    z-index: 200;
}

.web3modal-modal-lightbox {
    z-index: 5000 !important;
}

#modalcontainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.mintbutton {
    height: 40px;
    width: 200px;
    border: 1px solid #E2AE30;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: linear .5s;
    cursor: pointer;
    position: absolute;
    font-family: MontM;
    right: 35px;
    bottom: 2.7%;
    color: white;
}


.mintbutton:hover {
    color: #E2AE30;
    box-shadow: 0px 0px 20px 5px #E2AE30;
}


#fakemint {
    opacity: .3;
    cursor: default;
}

#remaining {
    color: #E2AE30;
    border: 4px solid #8E887A;
    padding: 12px;
    padding-top: 17px;
    font-family: MontM;
    font-size: 30px;
    min-width: 195px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .8) !important;
}


#details {
    text-align: justify;
    margin-right: 10px;
    margin-top: 20px;
    line-height: 140%;
    margin-bottom: 20px;
    font-size: 21px;
}


@media only screen and (min-width: 1100px) {


      #immlogo {
          top: 720px;
      }

      #mintcontent {
        width: 540px;
        height: 100%;
        transform: skew(-20deg);
        margin-left: 250px;
        display: flex;
        flex-direction: column;
    }

  
    #mintcols {
        display: flex;
        flex-direction: row;
        font-size: 21px;
    }
}

@media only screen and (max-width: 900px) {

    #immlogo {
        width: 450px;
        height: 170px;
    }

    #minttitle {
        font-size: 50px;
        letter-spacing: 8px;
    }

    #mintcontent {
        width: 540px;
    }

    #mintcols {
        font-size: 16.4px;
    }

    #remaining {
        font-size: 27px;
    }
}

@media only screen and (max-width: 700px) {

    #immlogo {
        top: 700px;
        width: 450px;
        height: 160px;

    }

    #details {
        margin-top: 35px;
        margin-bottom: 32px;
    }

    #remaining1 {
        margin-bottom: 20px;
    }


    #minttitle {
        margin-top: 120px;
        margin-bottom: 20px;
        display: block;
    }

    #mintcols {
        flex-direction: column;
        align-items: center;
    }

    #mintcontent {
        width: 70%;
    }

    #details {
        text-align: center;
    }

    #mintcol1 {
        width: 90%;
        margin-bottom: 20px;
        text-align: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        display: flex;
    }

    #mintmodalopen {
        margin: 0px;
        position: static;
    }
}

@media only screen and (max-width: 600px) {

    #immlogo {
        top: 700px;
        width: 320px;
        height: 120px;
    }
    
    #mintcontent {
        width: 88%;
    }

    #mintcol1 {
        width: 92%;
    }

    #mintcol2 {
        width: 92%;
    }
}


@media only screen and (max-width: 399px) {



    #details {
        margin-top: 40px;
        line-height: 170%;
        margin-bottom: 40px;
    }
    #immlogo {
        top: 772px;
        width: 260px;
        height: 92px;
    }
}


#mbc {
    display: flex;
    font-family: MontR;
    font-size: 25px;
    align-items: center;
}