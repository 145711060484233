.calendar-container {
    color: white;
    min-height: calc(100vh - 270px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
}

#calendar {
    width: 80vw;
    height: 50vw;
    margin-top: 70px;
    margin-bottom: 70px;
    max-width: 1300px;
    max-height: 900px;
}

@media only screen and (max-width: 1400px) {
    #calendar {
        width: 90vw;
        height: 60vw;
    }
   
}

@media only screen and (max-width: 1134px) {
    .calendar-container {
        min-height: calc(100vh - 170px);
    }
   
}


@media only screen and (max-width: 1050px) {
    #calendar {
        height: calc(90vw * 1)
    }
   
}

@media only screen and (max-width: 1050px) {
    #calendar {
        height: 750px;
    }
   
}