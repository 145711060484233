#section4container {
    width: 100vw;
    height: 2000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    font-family: MontR;
}

#S4banner {
    background-image: url('../../files/S4banner.png');
    position: absolute;
    width: 100%;
    height: calc(400px + 4vw);
    background-size: cover;
    z-index: 0;
    background-repeat: repeat;
}

#S4video {
    margin-top: 100px;
    width: 1000px;
    height: 562.5px;
    z-index: 1;
}

#S4TC1 {
    margin-top: 140px;
    width: 1050px;
    color: white;
    font-size: 18px;
}

#S4TC1title {
    color: #E2AE30;
    font-size: 49px;
}

#S4TC1text {
    text-align: justify;
    margin-top: 70px;
    line-height: 140%;
    margin-bottom: 35px;
}

#S4rarity {
    margin-top: 150px;
    width: 1050px;
    display: flex;
    font-family: MontR;
    color: white;
    font-size: 18px;
    line-height: 140%;
    text-align: justify;
}

#S4TC2title {
    margin-top: 100px;
    color: #E2AE30;
    font-size: 49px;
    margin-bottom: 70px;
}

#S4TC2subtitle {
    margin-top: 20px;
    font-size: 21px;
    color: #E2AE30;
}

#S4code {
    width: 600px;
}

@media only screen and (max-width: 1300px) {
    #S4TC1 {
        width: 800px;
    }

    #S4code {
        width: 500px;
    }

    #S4rarity {
        width: 950px;
    }
}

@media only screen and (max-width: 1070px) {
    #section4container {
        height: auto;
    }
    
    #S4banner {
        position: static;
        margin-bottom: -240px;
    }
    
    #S4video {
        width: 90vw;
        height: 50.6vw;
        margin-top: 0px;
        z-index: 1;
    }
    
    #S4TC1 {
        margin-top: 90px;
        width: 85vw;
    }

    #S4TC1text {
        margin-top: 40px;
    }
    
    
    #S4rarity {
        margin-top: 0px;
        width: 100vw;
        flex-direction: column;
        align-items: center;

    }

    #S4TC2 {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    
    #S4TC2title {
        color: #E2AE30;
        font-size: 38px;
        margin-bottom: 70px;
        margin-top: 40px;
    }

    #S4TC2text {
        width: 85vw;
        margin-bottom: 40px;
    }
    
    #S4code {
        width: 450px;
    }
}

@media only screen and (max-width: 500px) {
    #S4banner {
        height: 220px;
        margin-bottom: -100px;
    }

    #S4code {
        width: 95vw;
    }
}