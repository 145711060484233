body, html {
    margin: 0;
    background-color: black;
    font-family: Silka;
}

a {
    text-decoration: none;
    color: white;
}
#maincontainer {
    position: relative;
    overflow: hidden;
}

@font-face {
    font-family: Varela;
    src: url('../../files/VarelaRound-Regular.ttf');
  }

  @font-face {
    font-family: Silka;
    src: url('../../files/silka.ttf');
  }

  @font-face {
    font-family: SilkaL;
    src: url('../../files/Silka-Light.otf');
  }


  @font-face {
    font-family: MontSB;
    src: url('../../files/Montserrat-SemiBold.ttf');
  }

  @font-face {
    font-family: MontM;
    src: url('../../files/Montserrat-Medium.ttf');
  }

  @font-face {
    font-family: MontL;
    src: url('../../files/Montserrat-Thin.ttf');
  }

  @font-face {
    font-family: MontR;
    src: url('../../files/Montserrat-ExtraLight.ttf');
  }

  #fallbackcontainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100vh;
      width: 100vw;
      min-width: 300px;
      min-height: 300px;
      background-image: linear-gradient(to top, rgb(24, 24, 24), rgba(61, 61, 61, 1));
  }

  #fallbacktext {
      color: white;
      font-family: MontSB;
      font-size: 55px;
      text-align: center;
  }

  #fallbackbutton {
      font-size: 20px;
        display: flex;
        justify-content: center;
        color: white;
        align-items: center;
        background-color: black;
        width: 220px;
        height: 55px;
        border-radius: 6px;
        font-family: Varela;
        margin-top: 40px;
        transition: ease-in-out .5s;
  }

  #fallbackbutton:hover {
      box-shadow: 0px 0px 10px 1px #ffffff;
      background-color: #000000;
  }

#placeholdercontainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: MontM;
}

#placeholderbutton {
  padding: 13px 20px;
  font-family: MontR;
  font-size: 20px;
  border: 1px solid #E2AE30;
  transition: 1s ease-in-out;
  cursor: pointer;
}

#phtitle {
  color: #E2AE30;
  font-size: 50px;
  text-align: center;
}

#phtitle2 {
  margin-top: 20px;
  margin-bottom: 50px;
  color: white;
  font-size: 22px;
  text-align: center;
  font-family: MontR;

}



#placeholderbutton:hover {
  color: #E2AE30;
  box-shadow: 0px 0px 20px 5px #E2AE30;
}

#mynftmessage {
  text-align: center;
  font-size: 24px;
  align-self: flex-start;
}