#navcontainer {
    width: 100%;
    height: 100px;
    min-height: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 17px;
    font-family: MontR;
    letter-spacing: 1.2px;
    // position: absolute;
  }

  .selected {
      font-family: MontM;
      font-weight: 1000;
  }

  #navlogo {
      width: 120px;
      min-height: 65px;
      background-image: url('../../files/logo.png');
      background-size: cover;
      margin-right: 20px;
      margin-left: 30px;
  }

  #navline {
      height: 50px;
      width: 1px;
      background-color: gray;
      margin-right: 20px;
  }

.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 27px;
    top: 20px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #555868;
  }
  
  /* Position and sizing of clickable cross button */

.bm-cross-button {
    top: 20px !important;
    right: 27px !important;
    height: 39px !important;
    width: 36px !important;
  }

/* This targets the actual elements that make up the cross */
.bm-cross {
    width: 5px !important;
    height: 30px !important;
}
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    top: 0;
    height: 100%;
    display: block !important;
    width: 100vw !important;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: black;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: black;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    background-color: black;
    display: flex;
    justify-content: center;
    padding-right: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  #navulright {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    align-items: center;
    padding: 0px;
    font-family: MontL;
    margin-bottom: 26px;
  }

  #navulleft {
    display: flex;
    list-style-type: none;
    justify-content: center;
    align-items: flex-end;
    padding: 0px;
    font-size: 22px;
    margin-bottom: 20px;
  }

  #navtext {
      color: white;
      font-family: MontL;
      font-weight: 1000;
      font-size: 26px;
  }

  #navtitle {
      display: flex;
  }

  #navsubtitle {
      font-size: 16px;
      margin-top: 5px;
  }

  #tm {
      font-size: 10px;
  }

  .navli {
    line-height: 180%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-right: 27px;
  }

  .walletbutton {
    border: 1px solid #E2AE30;
    transition: 1s ease-in-out;
    padding: 5px 10px;
    cursor: pointer;
  }

  .walletbutton:hover {
    color: #E2AE30;
    box-shadow: 0px 0px 20px 5px #E2AE30;
}

@media only screen and (min-width: 1434px) {
  .navli {
    margin-right: 40px;
  }
}



@media only screen and (max-width: 1134px) {
  #navulright.bm-item {
    margin-left: 60px;
    font-size: 22px;
    margin-top: 80px;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    }

  .navli {
    margin-bottom: 20px;
  }

  .walletbutton {
    font-size: 20px;
  }

  #mobiledisc {
    margin-right: 25px;
  }
    
  #mobiletwit {
    margin-right: 25px;
  }

  #navsocials {
      width: 120px;
      display: flex;
      justify-content: space-between;
  }
}

